import axios from 'axios';

const API_URL = 'http://laundry.ezeehost.com:4909/ClientIspmateApi/';

class AuthService {
  login(user) {    
    return axios
      .post('http://laundry.ezeehost.com:4909/ClientIspmateApi/login', {
        username: user.username,
        password: user.password        
      })
      .then(response => {        
        if (response.data) {          
          localStorage.setItem('user', JSON.stringify(response.data));
          localStorage.setItem('token', response.data.data[0].token)
          localStorage.setItem('company_id', response.data.data[0].company_id)
        }
        //console.log(response.data.data[0].token);  
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem('user');
  }

  register(user) {
    return axios.post(API_URL + 'signup', {
      username: user.username,
      email: user.email,
      password: user.password
    });
  }
}

export default new AuthService();
