<template>
  <div class="col-md-12">
    <div class="card card-container">
      <img
        id="profile-img"
        src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
        class="profile-img-card"
      />
     
        <div class="form-group">
        <input type="number" class="form-control" autocomplete="off" name="OTP" placeholder="Enter OTP" id="OTP" v-model="OTP"/>
      </div>
      <div class="form-group">
        <input type="password" class="form-control" autocomplete="off" name="Password" placeholder="Enter New Password" id="Password" v-model="Password"/>
      </div> 
      
      <button class="btn btn-primary" @click="submit()">Reset Password</button>

        <div class="forgotalign">
         <a v-bind:href="'/login'"> Back Login</a>
        </div>

        <div class="form-group">
          <div v-if="message" class="alert alert-danger" role="alert">
            {{ message }}
          </div>
        </div>
      
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {    
  name: 'Resetpassword',  
    data(){
        return {   
            email: localStorage.email,
            OTP: '', 
            Password: ''           
        }
    },

    methods: {
        submit(){
            axios.post('http://laundry.ezeehost.com:4909/ClientIspmateApi/forgotPasswordUpdate', {user_id: this.email,OTP: this.OTP,Password: this.Password} , {
              user_id: this.email,
            })
                .then(response => {
                //alert("Form was submitted successfully");
                console.log(response.data);
                window.location.href = '/login';                
                //return response
                });
        }
    }
}
</script>



<style scoped>
.forgotalign { text-align:center; }
.forgotwdth {width:100%; }
.topb { margin-top: 0;}
label {
  display: block;
  margin-top: 10px;
}
.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}
.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}
.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.error-feedback {
  color: red;
}
</style>