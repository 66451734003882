import { createWebHistory, createRouter } from "vue-router";
import Home from "./components/Home.vue";
import Login from "./components/Login.vue";
import Forgotpassword from "./components/Forgotpassword.vue";
import Resetpassword from "./components/Resetpassword.vue";
// lazy-loaded
const Profile = () => import("./components/dashboard/Profile.vue")
//const BoardAdmin = () => import("./components/BoardAdmin.vue")
//const BoardModerator = () => import("./components/BoardModerator.vue")
//const BoardUser = () => import("./components/BoardUser.vue")


const routes =  [
{path: "/",name: "home",component: Home,},
{path: "/home",name: "home",component: Home,},
{path: "/login",name: "login",component: Login,},
{path: "/forgotpassword",name: "forgotpassword",component: Forgotpassword,},
{path: "/resetpassword",name: "resetpassword",component: Resetpassword,},
{path: "/",alias: "/servicelist",name: "servicelist",component: () => import("./components/service/TutorialsList")},
{path: "/add",name: "add",component: () => import("./components/service/addservices")}, 
{path: "/",alias: "/rolelist",name: "rolelist",component: () => import("./components/rolemanagement/Rolelist")},
{path: "/addrole",name: "addrole",component: () => import("./components/rolemanagement/Addrole")}, 
{path: "/roleedit",name: "roleedit",component: () => import("./components/rolemanagement/Roleedit")},
{path: "/",alias: "/stafflist",name: "stafflist",component: () => import("./components/staff/Stafflist")},
{path: "/addstaff",name: "addstaff",component: () => import("./components/staff/Addstaff")},
{path: "/editstaff",name: "editstaff",component: () => import("./components/staff/Editstaff")},
{path: "/",alias: "/neworder",name: "neworder",component: () => import("./components/order/Neworder")},
{path: "/",alias: "/getorderdetails",name: "getorderdetails",component: () => import("./components/order/Getorderdetails")},
{path: "/",alias: "/updateorder/:id",name: "updateorder",component: () => import("./components/order/Updateorder")},
{path: "/companylist",alias: "/companylist",name: "companylist",component: () => import("./components/company/Companylist")},
{path: "/addcompany",name: "addcompany",component: () => import("./components/company/Addcompany")},
{path: "/",alias: "/clientlist",name: "clientlist",component: () => import("./components/customer/ClientsList")},
{path: "/",alias: "/pendingorder",name: "pendingorder",component: () => import("./components/order/Pendingorder")},
{path: "/",alias: "/orderhistory",name: "orderhistory",component: () => import("./components/order/Orderhistory")},
{path: "/",alias: "/completeorder",name: "completeorder",component: () => import("./components/order/Completeorder")},
{path: "/",alias: "/changepassword",name: "changepassword",component: () => import("./components/Changepassword")},
{path: "/",alias: "/staffchangepassword",name: "staffchangepassword",component: () => import("./components/Staffchangepassword")},
{path: "/editservice",name: "editservice",component: () => import("./components/service/Editservice")},
{path: "/",alias: "/extrachargelist",name: "extrachargelist",component: () => import("./components/extra/ExtrachargeList")},
{path: "/editextracharge",name: "tutorial-details",component: () => import("./components/extra/Extracharge")},
{path: "/updatecustomer",name: "updatecustomer",component: () => import("./components/customer/Updatecustomer")},
{path: "/vieworder/:id",name: "vieworder",component: () => import("./components/order/Vieworder")},
{path: "/paymentreceipt/:id",name: "paymentreceipt",component: () => import("./components/Paymentreceipt")},
{path: "/paynow/:id",name: "paynow",component: () => import("./components/Paynow")},
{path: "/clientorder/:id",name: "clientorder",component: () => import("./components/customer/Clientorder")},
{path: "/clientlist/:id",name: "clientremove",component: () => import("./components/customer/ClientsList")},   
{path: "/profile",name: "profile",component: Profile,},
{path: "/addextra",name: "addextra",component: () => import("./components/extra/addextra")},
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/register', '/home', '/forgotpassword', '/resetpassword'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});

export default router;