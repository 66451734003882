<template>
  <div class="col-md-12">
    <div class="card card-container">
      <img
        id="profile-img"
        src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
        class="profile-img-card"
      />
     
        <div class="form-group">
          <label for="username">Email Id</label>
          <input type="email" class="form-control" autocomplete="off" name="user_id" placeholder="Enter Email Address" id="user_id" v-model="user_id"/>
        </div>        
        
        <div class="form-group topb">
          <button class="btn btn-primary forgotwdth" @click="submit()">Reset Password</button>
        </div>

        <div class="forgotalign">
         <a v-bind:href="'/login'"> Back Login</a>
        </div>

        <div class="form-group">
          <div v-if="message" class="alert alert-danger" role="alert">
            {{ message }}
          </div>
        </div>
      
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {    
  name: 'Tutorial',  
    data(){        
        return {            
            user_id: '',            
        }
    },

    methods: {
        submit(){
            axios.post('http://laundry.ezeehost.com:4909/ClientIspmateApi/sendotp', {user_id: this.user_id} , {
              user_id: this.user_id,
            })
                .then(response => {
                localStorage.setItem('email', this.user_id)    
                //alert("Form was submitted successfully");
                //this.email = this.user_id;
                console.log(response.data);
                window.location.href = '/resetpassword';                
                //return response
                });
        }
    }
}
</script>



<style scoped>
.forgotalign { text-align:center; }
.forgotwdth {width:100%; }
.topb { margin-top: 0;}
label {
  display: block;
  margin-top: 10px;
}
.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}
.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}
.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.error-feedback {
  color: red;
}
</style>